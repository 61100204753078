<template>
    <footer v-if="$root.user.user_type == 'client'" class="text-center mt-3 mb-3 filey-footer">Powered by Filey File Portal - File Sharing Made Simple - Copyright &copy; {{year}} <a href="https://www.netshapers.com" target="_blank">NetShapers, Inc.</a></footer>
</template>


<script>
    export default {
        data() {
            return {
                year: ''
            }
        },
        created() {
            axios.get(this.$root.apiurl+"/api/year")
                .then(
                    ({ data }) => (this.year = data.year)
                );
        }
    }
</script>