/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');     // This is not Twitter Bootstrap, just strapping up our boot...

window.Vue = require('vue');

// Register VForm
import { Form, HasError, AlertError } from 'vform'
window.Form = Form;
Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)

import objectToFormData from "./vform-objectToFormData"; 
window.objectToFormData = objectToFormData;

// Register VueProgressBar
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '3px'
})

// Register SweetAlert
import Swal from 'sweetalert2';
window.Swal = Swal;
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
window.Toast = Toast;       // Quick access to this toaster that we just defined

// New instance of Vue so we can make and "fire" custom events - 
window.Fire = new Vue();

// Register Moment.js
import moment from 'moment';

// Import Laravel-Vue-Pagination
Vue.component('pagination', require('laravel-vue-pagination'));

// Create prettyDateTime filter
Vue.filter('prettyDateTime', function(timestamp){
    return moment(timestamp).format('MM/DD/YYYY h:mm:ss a');
});

// Create prettyDate filter
Vue.filter('prettyDate', function(timestamp){
    return moment(timestamp).format('MM/DD/YYYY');
});

// Make filesize human readable
Vue.filter('prettyFileSize', function(fileSizeInBytes) {
    var i = 0;
    var byteUnits = [' B', ' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    while (fileSizeInBytes > 1024)  {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } 
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
});

// Bootstrap-Vue
import { BootstrapVue } from 'bootstrap-vue'

// Import our CSS
import '../sass/app.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

Vue.mixin({
    methods: {
        modalHack: function () {
            // Hack to add the 'filey' class to the boostrap-vue modal outer wrapper
            // iPhones make "static" b-modals pop up inside the parent container visually, 
            // so we have to add the class to all modals instead
            this.$nextTick(() => {              // Wait for the modal to load in the DOM
                var modalList;
                modalList = document.querySelectorAll('[id$=_BV_modal_outer_]');
                for (var i = 0; i < modalList.length; ++i) {
                    modalList[i].classList.add('filey');
                }
            });
        }
    },
})


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('main-component', require('./components/MainComponent.vue').default);
Vue.component('login-component', require('./components/LoginComponent.vue').default);
Vue.component('admin-component', require('./components/AdminComponent.vue').default);
Vue.component('client-component', require('./components/ClientComponent.vue').default);
Vue.component('file-list', require('./components/FileList.vue').default);
Vue.component('new-file-list', require('./components/NewFileList.vue').default);
Vue.component('add-file-button', require('./components/AddFileButton.vue').default);
Vue.component('request-file-button', require('./components/RequestFileButton.vue').default);
Vue.component('footer-component', require('./components/FooterComponent.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    data: {                     // Global vars - components reference like this.$root.access_token
        apiurl: '',             // The url of the back end API
        fronturl: '',           // The full url the page was served with
        subdomain: '',          // The subdomain the front end is being served from
        access_token: '',       // Logged in user access token
        adminName: '',          // Name for admin - either company or first name
        adminCompany: '',       // Main company name for admin
        paginationClients: '',  // Pagination size for Admin's client list
        user: {                 // Information about the logged in user
            id: '',
            first_name: '',
            user_type: '',
        }
    }
});
