<template>
    <div v-if="$root.user.user_type == 'admin'" class="container mt-3">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card user-list">
                    <div class="card-header">
                        <!-- <h2>Filey</h2>  -->
                         
                        <div style="float:left"><h2 style="margin-bottom:0;">Admin - {{$root.user.first_name}}</h2></div>
                        <div style="float:right"> 
                            <!-- <new-file-list-button /> -->
                            <span style="padding-right:10px;">
                                <button type="button" class="btn btn-primary btn-sm add-new-user-button-large" @click.prevent="addModal">
                                    Add New User <i class="fas fa-user-plus" style="padding-left:5px;"></i>
                                </button>
                                <span style="padding-right:1px; display:none;" class="add-new-user-button-small"><a href="#" @click.prevent="addModal">
                                    <i class="fas fa-user-plus" title="Add New User" style="padding-right:3px;"></i></a>
                                </span>
                            </span>
                            <span style="padding-right:10px;"><a href="#" @click.prevent="searchModal()" title="Client Search">
                                <i class="fas fa-search" style="padding-right:3px;"></i><span class="settings-button-label">Search</span></a>
                            </span>
                            <span style="padding-right:10px;"><a href="#" @click.prevent="settingsModal()" title="Settings">
                                <i class="fas fa-cog" style="padding-right:3px;"></i><span class="settings-button-label">Settings</span></a>
                            </span>
                            <span style="padding-right:10px;"><a href="#" @click.prevent="logOut" title="Log Out">
                                <i class="fas fa-sign-out-alt" style="padding-right:3px;"></i><span class="log-out-button-label">Log Out</span></a>
                            </span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover mb-20 user-list-table you-have-new-files-row">
                                <tr>
                                    <td v-if="!new_files_open" @click="openNewFiles()" class="expand">
                                        <i class="fas fa-caret-right" /></td>
                                    <td v-if="new_files_open" @click="openNewFiles()" class="expand">
                                        <i class="fas fa-caret-down" /></td>
                                    <td @click="openNewFiles()" class="pointer">New File List</td>
                                </tr>
                                <tr v-if="new_files_open">
                                    <td colspan="5" style="background-color: #f5f5f5">
                                        <div class="card file-list">
                                            <new-file-list />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="table-responsive">
                        <table class="table table-hover mb-0 user-list-table">
                            <thead>
                                <tr class="user-list-header">
                                    <th class="colCaret clear"></th>
                                    <th>Client Name</th>
                                    <th>Contact Person</th>
                                    <th>Phone Number</th>
                                    <th align="center" class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="user in users.data">
                                <tr v-bind:class="{ 'has-open-files': files_open.includes(user.id) }" :key="user.id">
                                    <td v-if="!files_open.includes(user.id)" @click="openFiles(user.id)" class="colCaret expand pointer">
                                        <i class="fas fa-caret-right" /></td>
                                    <td v-if="files_open.includes(user.id)" @click="openFiles(user.id)" class="colCaret expand pointer">
                                        <i class="fas fa-caret-down" /></td>
                                    <td v-if="user.user_type == 'admin'" @click="openFiles(user.id)" class="pointer">Admin</td> 
                                    <!-- <td v-if="user.user_type == 'admin'" @click="openFiles(user.id)">{{$root.adminCompany}}</td> -->
                                    <td v-if="user.user_type != 'admin'" @click="openFiles(user.id)" class="pointer">{{user.company_name}}</td>
                                    <td v-if="user.id != $root.user.id" @click="openFiles(user.id)" class="pointer">{{user.first_name}} {{user.last_name}}</td>
                                    <td v-if="user.id == $root.user.id" @click="openFiles(user.id)" class="pointer">{{user.first_name}} {{user.last_name}} (You)</td>
                                    <td @click="openFiles(user.id)" class="pointer">{{user.office_number}}</td>
                                    <td align="center">
                                        <add-file-button 
                                            :user_id="user.id" 
                                            :apiurl="apiurl"
                                            :company_name="user.company_name"
                                        />
                                        <request-file-button 
                                            :user_id="user.id" 
                                            :apiurl="apiurl"
                                            :company_name="user.company_name"
                                        />
                                        <a v-if="user.id != $root.user.id" href="#" @click.prevent="editModal(user)">
                                            <i class="fas fa-edit" title="Edit User"></i></a>
                                        <a v-if="user.id == $root.user.id" href="#" @click.prevent="settingsModal()">
                                            <i class="fas fa-edit" title="Edit User"></i></a>
                                            <!-- <span class="clear">&nbsp;</span> -->
                                        <a v-if="user.id != $root.user.id" href="#" @click.prevent="deleteUser(user.id, user.company_name)">
                                            <i class="fas fa-trash-alt" title="Delete User"></i></a>
                                        <a v-if="user.id == $root.user.id" href="#" class="btn-link disabled">
                                            <i class="fas fa-trash-alt" title="Delete User"></i></a>
                                    </td>
                                </tr>
                                <tr v-if="files_open.includes(user.id)">
                                    <td colspan="5" style="background-color: #f5f5f5">
                                        <div class="card file-list">
                                            <file-list v-if="files_open.includes(user.id)"
                                                :key="user.id"
                                                :user_id="user.id"
                                                :apiurl="apiurl"
                                            />
                                        </div>
                                    </td>
                                </tr>
                                </template>
                            </tbody>
                        </table>
                        <table v-if="loading" class="table mb-0 user-list-table loading">
                            <tr class="loading">
                                <!-- <td align="center" class="text-center"><span class="loading1">&#9679;</span> &nbsp; <span class="loading2">&#9679;</span> &nbsp; <span class="loading3">&#9679;</span></td> -->
                                <td align="center" class="text-center">
                                    <div>
                                        <b-spinner class="loading-user-list" label="Spinner"></b-spinner>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        </div>
                    </div><!-- card-body -->
                    <div v-if="!showing_single_client" id="pagination" style="margin-left:20px">
                        <pagination :data="users" @pagination-change-page="changePagination"></pagination>
                    </div>
                

                    <div v-if="showing_single_client" class="table table-hover mb-20 text-center">
                        <button type="button" class="btn btn-primary btn-sm center show-all-clients-button" @click.prevent="loadUsers()">
                            Show All Clients
                        </button>
                    </div>

                </div>
            </div>
        </div>

 


        <!-- User Modal -->
        <b-modal 
            id="userModal" 
            size="lg" 
            scrollable 
            centered 
            static
            modal-class="filey"
            :title="editmode? 'Update User':'Add New User'"
            :ok-title="editmode? 'Update User':'Add User'" 
            @ok.prevent="userOkButton"
        >
        <form @submit.stop.prevent="editmode ? updateUser() : addUser()" id="form" name="form">
            <!-- <div class="modal fade" id="userModal" tabindex="-1" role="dialog" aria-labelledby="userModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document"> 
                <div class="modal-content"> -->
                    <!-- <div class="modal-header">
                        <h5 v-if="editmode" class="modal-title" id="userModalLabel">Update User</h5>
                        <h5 v-if="!editmode" class="modal-title" id="userModalLabel">Add New User</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> -->
                    <div class="modal-body">
                        <div class="form-group row">
                            <label for="inputCompanyName" class="col-sm-5 col-form-label text-right">Company Name</label>
                            <div class="col-sm-7">
                                <input v-model="form.company_name" type="text" class="form-control" 
                                    name="customer_name" :class="{ 'is-invalid': form.errors.has('company_name') }">
                                <has-error :form="form" field="company_name"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputFirstName" class="col-sm-5 col-form-label text-right">First Name</label>
                            <div class="col-sm-7">
                                <input v-model="form.first_name" type="text" class="form-control" 
                                    name="first_name" :class="{ 'is-invalid': form.errors.has('first_name') }">
                                <has-error :form="form" field="first_name"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputLastName" class="col-sm-5 col-form-label text-right">Last Name</label>
                            <div class="col-sm-7">
                                <input v-model="form.last_name" type="text" class="form-control" 
                                    name="last_name" :class="{ 'is-invalid': form.errors.has('last_name') }">
                                <has-error :form="form" field="last_name"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputEmail" class="col-sm-5 col-form-label text-right">Email</label>
                            <div class="col-sm-7">
                                <input v-model="form.email" type="email" class="form-control" 
                                    name="email" :class="{ 'is-invalid': form.errors.has('email') }">
                                <has-error :form="form" field="email"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputOfficeNumber" class="col-sm-5 col-form-label text-right">Office Number</label>
                            <div class="col-sm-7">
                                <input v-model="form.office_number" type="tel" class="form-control"  
                                    name="office_number" :class="{ 'is-invalid': form.errors.has('office_number') }">
                                <has-error :form="form" field="office_number"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputMobileNumber" class="col-sm-5 col-form-label text-right">Mobile Number</label>
                            <div class="col-sm-7">
                                <input v-model="form.mobile_number" type="tel" class="form-control" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    name="mobile_number" :class="{ 'is-invalid': form.errors.has('mobile_number') }">
                                <has-error :form="form" field="mobile_number"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-5 col-form-label text-right">Password<br /><small>Leave blank for no change</small></label>
                            <div class="col-sm-7">
                                <input v-model="form.password" type="password" class="form-control" 
                                    name="password" :class="{ 'is-invalid': form.errors.has('password') }">
                                <has-error :form="form" field="password"></has-error>
                            </div>
                        </div>
                        <!-- <div class="form-group row">
                            <label for="inputPreferredAuthMethod" class="col-sm-5 col-form-label text-right">Preferred Authentication Method</label>
                            <div class="col-sm-7">
                                <select v-model="form.preferred_auth_method" type="select" class="form-control" name="form.preferred_auth_method">
                                    <option value="password">Password</option>
                                    <option value="otp">Send One Time Password Via Text</option>
                                </select>
                                <has-error :form="form" field="preferred_auth_method"></has-error>
                            </div>
                        </div> -->
                        <div class="form-group row">
                            <label for="inputUserType" class="col-sm-5 col-form-label text-right">User Type</label>
                            <div class="col-sm-7">
                                <select v-model="form.user_type" type="select" class="form-control" name="form.user_type">
                                    <option value="client">Client</option>
                                    <option value="admin">Admin</option>
                                </select>
                                <has-error :form="form" field="user_type"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputNotes" class="col-sm-5 col-form-label text-right">Client Notes</label>
                            <div class="col-sm-7">
                                <textarea v-model="form.notes" type="text" class="form-control" rows="2"
                                    name="notes" placeholder="Notes about this client for your eyes only"
                                    :class="{ 'is-invalid': form.errors.has('notes') }"></textarea>
                                <has-error :form="form" field="notes"></has-error>
                            </div>
                        </div>
                        <div v-if="form.created_at" class="form-group row">
                            <label for="inputCreatedAt" class="col-sm-5 col-form-label text-right">User Created at</label>
                            <div class="col-sm-7 col-form-label ">{{form.created_at | prettyDateTime}}</div>
                        </div>
                    </div>
                    <!-- <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-if="editmode" type="submit" class="btn btn-primary">Update User</button>
                        <button v-if="!editmode" type="submit" class="btn btn-primary">Add User</button>
                    </div> -->
                    
                <!-- </div> -->
            <!-- </div> -->
            </form>
        </b-modal><!-- User Modal -->

        <!-- Settings Modal -->
        <b-modal 
            id="settingsModal" 
            size="lg" 
            scrollable 
            centered 
            static
            title="Your Settings"
            ok-title="Update Settings" 
            @ok="updateSettingsOkButton"
        >
            <form @submit.stop.prevent="updateSettings()">
            <!-- <template #modal-title>Your Settings</template> -->
            <!-- <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document"> -->
                <!-- <div class="modal-content"> -->
                    <!-- <div class="modal-header">
                        <h5 class="modal-title" id="settingsModalLabel">Your Settings</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> -->
                    <div class="modal-body">
                        <div class="form-group row">
                            <label for="inputFirstName" class="col-sm-5 col-form-label text-right">First Name</label>
                            <div class="col-sm-7">
                                <input v-model="form.first_name" type="text" class="form-control" 
                                    name="first_name" :class="{ 'is-invalid': form.errors.has('first_name') }">
                                <has-error :form="form" field="first_name"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputLastName" class="col-sm-5 col-form-label text-right">Last Name</label>
                            <div class="col-sm-7">
                                <input v-model="form.last_name" type="text" class="form-control" 
                                    name="last_name" :class="{ 'is-invalid': form.errors.has('last_name') }">
                                <has-error :form="form" field="last_name"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputEmail" class="col-sm-5 col-form-label text-right">Email</label>
                            <div class="col-sm-7">
                                <input v-model="form.email" type="email" class="form-control" 
                                    name="email" :class="{ 'is-invalid': form.errors.has('email') }">
                                <has-error :form="form" field="email"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputOfficeNumber" class="col-sm-5 col-form-label text-right">Office Number</label>
                            <div class="col-sm-7">
                                <input v-model="form.office_number" type="tel" class="form-control"  
                                    name="office_number" :class="{ 'is-invalid': form.errors.has('office_number') }">
                                <has-error :form="form" field="office_number"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputMobileNumber" class="col-sm-5 col-form-label text-right">Mobile Number</label>
                            <div class="col-sm-7">
                                <input v-model="form.mobile_number" type="tel" class="form-control" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" title="225-555-1234"
                                    name="mobile_number" :class="{ 'is-invalid': form.errors.has('mobile_number') }">
                                <has-error :form="form" field="mobile_number"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-5 col-form-label text-right">Password<br /><small>Leave blank for no change</small></label>
                            <div class="col-sm-7">
                                <input v-model="form.password" type="password" class="form-control" 
                                    name="password" :class="{ 'is-invalid': form.errors.has('password') }">
                                <has-error :form="form" field="password"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3"><hr /></div>
                            <div class="col-sm-6">
                                <h3 class="text-center">Company Settings</h3>
                            </div>
                            <div class="col-sm-3"><hr /></div>
                        </div>
                        
                        <div class="form-group row">
                            <label for="inputCompanyName" class="col-sm-5 col-form-label text-right" data-toggle="tooltip" data-placement="left" title="Tooltip on left">Company Name</label>
                            <div class="col-sm-7">
                                <input v-model="form.admin_company" type="text" class="form-control" 
                                    name="admin_company" :class="{ 'is-invalid': form.errors.has('admin_company') }">
                                <has-error :form="form" field="admin_company"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputAdminName" class="col-sm-5 col-form-label text-right">Admin Name<br /><small>What clients see for "uploaded by" or "send to"</small></label>
                            <div class="col-sm-7">
                                <input v-model="form.admin_name" type="text" class="form-control" 
                                    name="admin_name" :class="{ 'is-invalid': form.errors.has('admin_name') }">
                                <has-error :form="form" field="admin_name"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputAdminEmail" class="col-sm-5 col-form-label text-right">Address that emails are sent from<br />and new file alerts are sent to</label>
                            <div class="col-sm-7">
                                <input v-model="form.admin_email" type="email" class="form-control" 
                                    name="admin_email" :class="{ 'is-invalid': form.errors.has('admin_email') }">
                                <has-error :form="form" field="admin_email"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputPagination" class="col-sm-5 col-form-label text-right">Number of clients listed per page</label>
                            <div class="col-sm-7">
                                <input v-model="form.pagination_clients" type="text" class="form-control" 
                                    name="pagination_clients" :class="{ 'is-invalid': form.errors.has('pagination_clients') }">
                                <has-error :form="form" field="pagination_clients"></has-error>
                            </div>
                        </div>

                    </div>
                    <!-- <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Update Settings</button>
                    </div> -->
                <!-- </div> -->
            <!-- </div> -->
            </form>
        </b-modal><!-- Settings Modal -->


<!-- Search Modal -->
        <b-modal 
            id="searchModal" 
            size="lg" 
            scrollable 
            centered 
            static
            modal-class="filey"
            title="Client Search"
            @shown="focusSearchbox"
            hide-footer
        >
            <div class="modal-body">
                <div class="form-group row">
                    <label for="searchbox" class="col-form-label">Type at least three letters of the client name that you are searching for:</label>
                    <input id="searchbox" name="searchbox" ref="searchbox" type="text" placeholder="Client Name" v-model="searchbox" v-on:keyup="autoComplete" class="form-control searchbox">
                    <div class="searchresults">
                        <div v-if="got_search_results">
                            <ul class="list-group" v-if="results.length">
                                <li class="list-group-item" v-for="result in results">
                                    <a href="#" @click.prevent="loadSingleUser(result.id)">{{ result.company_name }} - {{ result.first_name }} {{ result.last_name }}</a>
                                </li>
                            </ul>
                            <ul class="list-group" v-if="!results.length">
                                <li class="list-group-item" v-if="!results.length">No Results</li>
                            </ul>
                            
                        </div>
                        <table v-if="loading_search" class="table mb-0 user-list-table loading">
                            <tr class="loading">
                                <!-- <td align="center" class="text-center"><span class="loading1">&#9679;</span> &nbsp; <span class="loading2">&#9679;</span> &nbsp; <span class="loading3">&#9679;</span></td> -->
                                <td align="center" class="text-center">
                                    <div>
                                        <b-spinner label="Spinner"></b-spinner>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </b-modal><!-- Search Modal -->



    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    id: '',
                    email: '',
                    password: '',
                    company_name: '',
                    first_name: '',
                    last_name: '',
                    notes: '',
                    office_number: '',
                    mobile_number: '',
                    preferred_auth_method: '',
                    user_type: 'client',
                    created_at: '',

                    admin_name: '',
                    admin_email: '',
                    admin_company: '',
                    pagination_clients: '',
                }),
                users: {},
                files_open: [],             // Allows for mutiple file sets to be open
                new_files_open: false,    // Toggle new files list
                editmode: true,             // Toggle modal mode from edit user to add user
                apiurl: this.$root.apiurl,      // Pull from the global set by the login component
                loading: true,
                loading_search: false,
                searchbox: '',
                results: [],
                got_search_results: false,
                showing_single_client: false,
                // api_url: process.env.MIX_API_URL,  // How to use .env vars; We're not using this one
            }
        },        
        methods: {
            loadUsers(){
                this.loading = true;
                this.users.data = [];
                this.$Progress.start();                         // Start the progress bar
                console.log('loadUsers: Loading users from api: ' + this.$root.apiurl);
                axios.get(this.$root.apiurl+"/api/user?pagination="+this.$root.paginationClients)
                    .then( data => {
                        this.users = data.data;
                        this.loading = false;
                    }).catch( err => {
                        console.log('loadUsers: ' + err)
                    });
                this.showing_single_client = false;
                this.$Progress.finish();                // Finish the progress bar
            },
            loadSingleUser(id){
                this.loading = true;
                this.$bvModal.hide('searchModal');
                this.users.data = [];
                this.$Progress.start();                         // Start the progress bar
                console.log('loadSingleUser: Loading user from api: ' + this.$root.apiurl);
                axios.get(this.$root.apiurl+"/api/user/"+id)
                    .then(data => {
                        this.showing_single_client = true;
                        this.users.data = [];
                        this.users.data[0] = data.data;
                        this.loading = false;
                        this.searchbox = '';
                        this.results = [];
                    }).catch( err => {
                        console.log('loadUsers: ' + err)
                    });
                this.$Progress.finish();                // Finish the progress bar
            },
            changePagination(page = 1) {
                axios.get(this.$root.apiurl+"/api/user?pagination="+this.$root.paginationClients+"&page="+page)
                    .then(response => {
                        this.users = response.data;
                    });
            },
            loadSettings(){               
                console.log('loadSettings');
                return axios.get(this.$root.apiurl+"/api/getadminsettings")
            },
            addModal() {
                this.editmode = false;
                this.form.reset();
                this.form.errors.clear();
                this.$bvModal.show('userModal');
            },
            addUserOkButton(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.addUser()
            },
            addUser(){
                this.$Progress.start();                         // Start the progress bar
                this.form.post(this.$root.apiurl+'/api/user')          // Save the data
                    .then( ()=>{                                // If successful:
                        Fire.$emit('ReloadUsers')               // Reload users
                        Toast.fire({                            // Pop up success message
                            icon: 'success',
                            title: 'User Added Successfully'
                        });
                        this.$Progress.finish();                // Finish the progress bar
                        // $('#userModal').modal('hide')           // Close the modal window
                        this.$bvModal.hide('userModal');
                    })
                    .catch( err =>{
                        // console.log(err);
                        this.$Progress.fail();                  // Finish the progress bar
                    }); 
            },
            editModal(user) {
                this.editmode = true;
                this.form.reset();
                this.form.errors.clear();
                this.$bvModal.show('userModal');
                this.form.fill(user);
            },
            userOkButton(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                if(this.editmode)
                    this.updateUser();
                else
                    this.addUser();
            },
            updateUser(){
                this.$Progress.start(); 
                this.form.put(this.$root.apiurl+'/api/user/'+this.form.id)         // Save the data
                    .then( ()=>{                                // If successful:
                        Fire.$emit('ReloadUsers')               // Reload users
                        Toast.fire({                            // Pop up success message
                            icon: 'success',
                            title: 'User Updated Successfully'
                        })
                        this.$Progress.finish();                // Finish the progress bar
                        // $('#userModal').modal('hide')           // Close the modal window
                        this.$bvModal.hide('userModal');
                    })
                    .catch( err =>{
                        // console.log(err);
                        this.$Progress.fail();                  // Finish the progress bar
                    }); 
            },
            deleteUser(id, username) {
                Swal.fire({
                    html: 'Are you sure you want to delete the user <br />' + username + '<br /> and all their files?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        if (result.value) {                         // If the user confirmed, 
                            console.log('deleting user...');
                            this.form.delete(this.$root.apiurl+'/api/user/'+id)       // Send request to server
                                .then(()=>{                         // Pop up success message
                                    Swal.fire(
                                    'Deleted!',
                                    'The user has been deleted.',
                                    'success'
                                    )
                                    Fire.$emit('ReloadUsers')       // Reload users
                                    this.$Progress.finish();    // Finish the progress bar
                                    })
                                .catch(()=> {
                                    this.$Progress.fail();          // Finish the progress bar
                                });
                            }
                        })
            },
            settingsModal() {
                this.form.reset();
                this.form.errors.clear();
                this.$Progress.start();                         // Start the progress bar
                console.log('settingsModal: Loading user info from api: ' + this.$root.apiurl);
                // do the axios request to get user info
                axios.get(this.$root.apiurl+"/api/user/"+this.$root.user.id)
                    .then( res => {
                        // console.log(res);
                        this.form.fill(res.data);  
                        this.form.admin_name = this.$root.adminName;
                        this.form.admin_email = this.$root.adminEmail;
                        this.form.pagination_clients = this.$root.paginationClients;
                        // do the axios request to get the admin settings
                        axios.get(this.$root.apiurl+"/api/getadminsettings")
                            .then( res => {
                                // console.log(res);
                                this.form.admin_name = res.data.adminName;
                                this.form.admin_email = res.data.adminEmail;
                                this.form.admin_company = res.data.adminCompany;
                                this.form.pagination_clients = res.data.paginationClients;  
                                this.$Progress.finish();        // Finish the progress bar
                            }).catch( err => {
                                console.log('settingsModal: ' + err)
                            });
                    }).catch( err => {
                        console.log('settingsModal: ' + err)
                    });
                this.$bvModal.show('settingsModal');
            },
            updateSettingsOkButton(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.updateSettings()
            },
            updateSettings(){
                this.$Progress.start(); 
                this.form.post(this.$root.apiurl+'/api/updateadminsettings')
                    .then( ()=>{                                // If successful:
                        Toast.fire({                            // Pop up success message
                            icon: 'success',
                            title: 'Settings Updated Successfully'
                        })
                        this.$root.user.first_name = this.form.first_name;
                        this.$root.adminName = this.form.admin_name;
                        this.$root.adminCompany = this.form.admin_company;
                        this.$root.paginationClients = this.form.pagination_clients;
                        this.$Progress.finish();                // Finish the progress bar
                        // $('#settingsModal').modal('hide')       // Close the modal window
                        this.$bvModal.hide('settingsModal');
                        this.loadUsers();
                    })
                    .catch( err =>{
                        console.log(err);
                        this.$Progress.fail();                  // Finish the progress bar
                    }); 
            },
            openFiles(id){
                // Go ahead and close the new files list in case files get downloaded
                this.new_files_open = false;

                // We can't just update an array because the component won't react.
                // It does react to pushes and splices, though...


                // This only allows for one file set to be open at a time
                if(this.files_open.includes(id)){       // If it's open, close it.
                    this.files_open.splice(0, 1)
                } else {
                    this.files_open.splice(0, 1)        // Otherwise, close others and open it.
                    this.files_open.push(id)
                }

                    // This allows for multiple file sets to be open
                // const index = this.files_open.indexOf(id);
                // if (index > -1) {
                //     this.files_open.splice(index, 1)
                // } else {
                //     this.files_open.push(id)
                // }
                
                
                // console.log('Set files_open ' + id);
            },
            openNewFiles() {
                // Go ahead and close any open client file lists
                this.files_open.splice(0, 1);
                
                // Toggle the new files list
                this.new_files_open = !this.new_files_open;
            },
            newFile(id){
                console.log('newFile, id= '+id);
                this.$refs['fileList' + id].addModal();
            },
            searchModal() {
                this.searchbox = '';
                this.results = [];
                this.$bvModal.show('searchModal');
            },
            focusSearchbox() {
                this.$refs.searchbox.focus();
            },
            autoComplete(){
                this.results = [];
                this.got_search_results = false;
                this.loading_search = true;
                if(this.searchbox.length > 2){
                    axios.get(this.$root.apiurl+'/api/search',{params: {query: this.searchbox}})
                        .then(response => {
                            this.results = response.data;
                            this.loading_search = false;
                            this.got_search_results = true;
                        })
                        .catch(error => {
                            console.log(error)
                        });
                } else {
                    this.loading_search = false;
                }
            },
            logOut() {
                Fire.$emit('logOut')                    // Call the logOut method in LoginComponent
            },
        },
        created() {
            // console.log('API URL: ' + this.$root.apiurl);    // API URL is set in filey.html
            // console.log('NODE_ENV: '+ process.env.NODE_ENV);
           
            Fire.$on('ReloadUsers',() => {               // Register the ReloadUsers event
                this.loadUsers();
            });
        }
    }
</script>
