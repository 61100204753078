<template>
<div  v-if="!$root.access_token" class="container mt-5">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">File Portal Log In</div>

                <div class="card-body">
                    <form v-if="showLoginChoice" @submit.prevent="submitLoginChoice" name="loginChoice">

                        <div class="form-group row">
                            <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address:</label>

                            <div class="col-md-6">
                                <input type="email" class="form-control" 
                                    name="email" v-model="form.email" required autofocus />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col text-center">
                                <hr width="90%" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col text-center">
                                <button @click="loginChoice='password'" value="usePassword" type="submit" name="submit" class="btn btn-primary">
                                    Log in with your password
                                </button>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col text-center">
                                <button @click="canTheyOTP(form.email)" value="useOTP" type="submit" name="submit" class="btn btn-primary">
                                    Log in with a code texted to your phone
                                </button>
                            </div>
                        </div>
                    </form>           

                    <form v-if="showPassword" @submit.prevent="submitPassword">
                        <div class="form-group row">
                            <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address:</label>

                            <div class="col-md-6">
                                <input type="email" class="form-control"  
                                @click.prevent="showLoginChoice = true; showPassword=false" 
                                    name="email" v-model="form.email" required  />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col text-center">
                                <hr width="90%" />
                            </div>
                        </div>
                        <div class="form-group row text-center">
                            <label for="password" class="col-md-4 col-form-label text-md-right">Password:</label>
                            <div class="col-md-6">
                                <input id="password" ref="password" type="password" class="form-control"
                                    name="password" v-model="form.password" required autofocus />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col text-center">
                                <button type="submit" class="btn btn-primary">
                                    Log in
                                </button>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <div class="col text-center">
                                <a href="#" @click.prevent="useForgotPassword()">Don't remember your password?</a>
                            </div>
                        </div>
                    </form>

                    <form v-if="showForgotPassword" @submit.prevent="submitPasswordReset">
                        <div class="form-group row">
                            <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address:</label>

                            <div class="col-md-6">
                                <input type="email" class="form-control" disabled
                                    name="email" v-model="form.email" required />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col text-center">
                                <hr width="90%" />
                            </div>
                        </div>
                        <div v-show="showForgotPassword1">
                            <div class="form-group row text-center">
                                <div class="col-md-12 text-center mb-3">We will email you a code to reset your password</div>
                            </div>
                            <div class="form-group row">
                                <div class="col text-center">
                                    <button @click="sendEmail(form.email)" type="button" class="btn btn-primary">
                                        Email me the code
                                    </button>
                                    <button @click="showLoginChoice=true; showForgotPassword=false; showForgotPassword1=false" type="button" class="btn btn-primary">
                                        No thanks, let's go back
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-show="showForgotPassword2">
                            <div class="form-group row text-center">
                                <div class="col-md-12 text-center mb-3">We have emailed a code to your email address above.<br />
                                When you get it, enter it below.</div>
                            </div>
                            <div class="form-group row">
                                <label for="resetpasswordcode" class="col-md-4 col-form-label text-md-right">Enter Code:</label>
                                <div class="col-md-6">
                                    <input id="resetpasswordcode" ref="resetpasswordcode" type="text" class="form-control"
                                        name="resetpasswordcode" v-model="form.resetpasswordcode" required autofocus />
                                    <has-error :form="form" field="resetpasswordcode"></has-error>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="newpassword" class="col-md-4 col-form-label text-md-right">New Password:</label>
                                <div class="col-md-6">
                                    <input id="newpassword" ref="newpassword" type="password" class="form-control"
                                        name="newpassword" v-model="form.newpassword" required autofocus />
                                    <has-error :form="form" field="newpassword"></has-error>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="newpasswordagain" class="col-md-4 col-form-label text-md-right">New Password Again:</label>
                                <div class="col-md-6">
                                    <input id="newpasswordagain" ref="newpasswordagain" type="password" class="form-control"
                                        name="newpasswordagain" v-model="form.newpasswordagain" required autofocus />
                                    <has-error :form="form" field="newpasswordagain"></has-error>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary">
                                        Update password and log In
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    
                    <form v-if="showOTP" @submit.prevent="submitOTP">
                        <div class="form-group row">
                            <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address:</label>

                            <div class="col-md-6">
                                <input type="email" class="form-control" disabled
                                    name="email" v-model="form.email" required />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col text-center">
                                <hr width="90%" />
                            </div>
                        </div>
                        <div v-show="showOTP1">
                            <div class="form-group row text-center">
                                <div class="col-md-12 text-center mb-3">Instead of having to remember your password to log in,<br />
                                we can text a code to your phone number ending in ...{{mobileend}}.</div>
                            </div>
                            <div class="form-group row">
                                <div class="col text-center">
                                    <button @click="sendOTP(form.email)" type="button" class="btn btn-primary">
                                        Send me the text
                                    </button>
                                    <button @click="showOTP1=false; showOTP2=true" type="button" class="btn btn-primary">
                                        I have a code
                                    </button>
                                    <button @click="showLoginChoice = true; showOTP=false; showOTP1=false" type="button" class="btn btn-primary">
                                        No thanks, let's go back
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-show="showOTP2">
                            <!-- <div class="form-group row text-center">
                                <div class="col-md-12 text-center mb-3">We have texted a code to your phone number ending in ...{{mobileend}}.<br />
                                When you get it, enter it below.</div>
                            </div> -->
                            <div class="form-group row">
                                <label for="otp" class="col-md-4 col-form-label text-md-right">Enter Code:</label>
                                <div class="col-md-6">
                                    <input id="otp" ref="otp" type="text" class="form-control"
                                        name="otp" v-model="form.otp" required autofocus />
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col text-center">
                                    <button @click="showLoginChoice = true; showOTP=false; showOTP1=false" type="button" class="btn btn-secondary">
                                        Go back
                                    </button>
                                    <button type="submit" class="btn btn-primary">
                                        Log In
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="showOTPSorry">
                            <div class="form-group row text-center">
                                <div class="col-md-12 text-center mb-3">Oops!  It looks like we don't have your phone number on file.<br />
                                To use this feature, please get with the admin to add it.</div>
                            </div>
                            <div class="form-group row">
                                <div class="col text-center">
                                    <button @click="showLoginChoice = true; showOTP=false; showOTPSorry=false" type="button" class="btn btn-primary">
                                        Go back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    email: '',
                    password: '',
                    newpassword: '',
                    newpasswordagain: '',
                    resetpasswordcode: '',
                    fronturl: '',
                    subdomain: '',
                }),
                loginChoice: '',
                showLoginChoice: true,
                showPassword: false,
                showForgotPassword: false,
                showForgotPassword1: false,
                showForgotPassword2: false,
                showOTP: false,
                showOTP1: false,
                showOTP2: false,
                showOTPSorry: false,
                mobileend: '',
            }
        },
        methods: {
            submitLoginChoice() {
                if (this.loginChoice == 'password') {
                    this.usePassword();
                }
                if (this.loginChoice == 'otp') {
                    this.useOTP();
                }
            },
            loadSettings() {
                // console.log('loadSettings');\
                return new Promise((resolve, reject) => {
                    axios.get(this.$root.apiurl+"/api/getgeneralsettings")
                        .then( res => {
                            console.log(res);
                            this.$root.adminName = res.data.adminName;
                            this.$root.paginationClients = res.data.paginationClients;
                            this.$root.adminCompany = res.data.adminCompany;
                            // console.log('success in loadsettings');
                            resolve('General settings loaded. ' + res.data.message);
                        }).catch( err => {
                            console.log('error in loadsettings');
                            reject('Error in loadsettings');
                    })
                })
            },
            logIn() {      // Once the user is authenticated, get them a token and load settings
                
                // Get info about this logged in user from the database/API
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$root.access_token;
                axios.get(this.$root.apiurl+"/api/currentuserinfo")
                .then( res => {
                    console.log('Got info for user ' + res.data.first_name);
                    // Set the info for the logged in user to our global vue variables
                    this.$root.user.id = res.data.id;
                    this.$root.user.first_name = res.data.first_name;
                    this.$root.user.user_type = res.data.user_type;

                    // Load General Settings
                    this.loadSettings()
                        .then ( res => {
                            console.log(res);
                            this.$Progress.finish();
                            if(this.$root.user.user_type == 'admin') {
                                Fire.$emit('ReloadUsers'); 
                            }
                        })
                        .catch ( err => {
                            // console.log(err);

                            this.$Progress.fail();
                        });
                }).catch( err => {
                    // If the user's info isn't found in the API, they may have an old token,
                    // so send them back to the login to reauthenticate.
                    this.$root.access_token = null;

                    console.log('Sending back to login screen...');
                    console.log('currentuserinfo error: ' + err);
                    this.$Progress.fail();
                });
            },
            logOut() {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, log me out!'
                    }).then((result) => {
                        if (result.value) {                         // If the user confirmed, 
                            console.log('logging out...');
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$root.access_token;
                            axios.post(this.$root.apiurl+"/api/logout")   // Send request to server
                                .then(()=>{                         // Pop up success message
                                    Swal.fire(
                                    'Bye!',
                                    'You are now logged out.',
                                    'success'
                                    );
                                    this.$root.access_token = '';
                                    document.cookie = '';           // Delete the browser cookie
                                    this.$root.user.user_type = '';
                                    this.$root.user.first_name = '';
                                    this.form.reset();              // Clear the login form
                                    this.form.subdomain = this.$root.subdomain; // but keep subdomain
                                    this.$Progress.finish();        // Finish the progress bar
                                    })
                                .catch(()=> {
                                    this.$Progress.fail();          // Finish the progress bar
                                });
                            }
                        })
            },
            usePassword() {
                this.showLoginChoice = false;
                this.showPassword = true;
                this.$nextTick(() => {              // We need to wait for the form to load in the DOM
                    this.$refs.password.focus();    // before we focus to the field
                });
            },
            useForgotPassword() {
                this.showPassword = false;
                this.showForgotPassword = true;
                this.showForgotPassword1 = true;
            },
            useOTP() {
                this.showLoginChoice = false;
                this.showOTP = true;
                this.showOTP1 = true;
            },
            canTheyOTP() {
                this.$Progress.start();
                this.loginChoice='otp'
                this.form.post(this.$root.apiurl+'/api/canotp')                      
                    .then( res => { 
                        console.log('canTheyOTP Success:');
                        console.log(res);
                        this.mobileend = res.data;
                        this.showOTP1 = true; 
                        this.showOTPSorry = false;
                        this.$Progress.finish();
                    })
                    .catch( err => {
                        console.log('canTheyOTP Error:');
                        console.log(err);
                        this.showOTP1 = false; 
                        this.showOTPSorry = true;
                        this.$Progress.fail();
                    })
            },
            sendOTP() {
                this.showOTP1 = false;
                this.showOTP2 = true;

                this.$Progress.start();
                this.form.post(this.$root.apiurl+'/api/sendotp')
                    .then( res => { 
                        console.log('sendotp Success:');
                        console.log(res);
                        this.$Progress.finish();
                    })
                    .catch( err => {
                        console.log('sendotp Error:');
                        console.log(err);
                        this.$Progress.fail();
                    })


                this.$nextTick(() => {              // We need to wait for the form to load in the DOM
                    this.$refs.otp.focus();         // before we focus to the field
                });
            },
            sendEmail() {
                this.showForgotPassword1=false; 
                this.showForgotPassword2=true;
                this.form.newpassword = '';
                this.form.newpasswordagain = '';
                this.form.resetpasswordcode = '';
                this.$Progress.start();
                this.form.fronturl = this.$root.fronturl;
                this.form.post(this.$root.apiurl+'/api/sendpasswordresetemail')                      
                    .then( res => { 
                        console.log('sendpasswordresetemail Success:');
                        console.log(res);
                        this.$Progress.finish();
                    })
                    .catch( err => {
                        console.log('sendpasswordresetemail Error:');
                        console.log(err);
                        this.$Progress.fail();
                    })

                this.$nextTick(() => {              // We need to wait for the form to load in the DOM
                    this.$refs.resetpasswordcode.focus();  // before we focus to the field
                });
            },
            showForgotPasswordForm() {
                this.loginChoice = 'password';
                this.showLoginChoice = false;
                this.showForgotPassword = true;
                this.showForgotPassword1 = false; 
                this.showForgotPassword2 = true;
            },
            submitPassword() {
                this.$Progress.start();
                this.form.post(this.$root.apiurl+'/api/login')                      
                    .then( res => { 
                        console.log(this.form.email + ' Authenticated!');
                        this.$root.access_token = res.data.access_token;  // Update our global token
                        document.cookie = 'fileytoken=' + res.data.access_token + '; SameSite=None; Secure';
                        // document.cookie = res.data.access_token;  // Store the token to browser cookie
                        this.logIn();
                    })
                    .catch( err => {
                        Swal.fire(
                            'Sorry, login failed',
                            'You may try again up to 5 times',
                            'error'
                        )
                        console.log(err);
                        this.$Progress.fail();
                    })
                this.showLoginChoice = true;
                this.showPassword = false;
            },
            submitPasswordReset() {
                this.$Progress.start();
                this.form.post(this.$root.apiurl+'/api/resetpassword')
                    .then( res => { 
                        Swal.fire(
                            'Password Updated!',
                            'You are now logged in',
                            'success'
                        )
                        console.log(this.form.email + ' Password Reset!');
                        this.$root.access_token = res.data.token;  // Update our global token
                        document.cookie = 'fileytoken=' + res.data.token + '; SameSite=None; Secure';
                        // document.cookie = res.data.token;  // Store the token to browser cookie
                        this.logIn();
                        this.showLoginChoice = true;
                        this.showPassword = false;
                        this.showForgotPassword = false;
                        this.showForgotPassword2 = false;
                    })
                    .catch( err => {
                        console.log(err.response.data.message);
                        if (err.response.data.message == 'Code expired') {
                            Swal.fire(
                                'Code Expired',
                                'You will need to initiate another password reset',
                                'error'
                            ).then((result) => {
                                    this.showLoginChoice = true;
                                    this.showPassword = false;
                                    this.showForgotPassword = false;
                                    this.showForgotPassword2 = false;
                                })
                        }
                        else {
                            Swal.fire(
                                'Sorry, login failed',
                                'You may try again up to 5 times',
                                'error'
                            )
                        }
                        console.log(err);
                        this.$Progress.fail();
                    })
            },
            submitOTP() {
                this.$Progress.start();
                this.form.post(this.$root.apiurl+'/api/loginotp')                      
                    .then( res => { 
                        console.log(this.form.email + ' Authenticated!');
                        this.$root.access_token = res.data.token;  // Update our global token
                        document.cookie = 'fileytoken=' + res.data.token + '; SameSite=None; Secure';
                        // document.cookie = res.data.token;  // Store the token to browser cookie
                        this.logIn();
                        this.showLoginChoice = true;
                        this.showOTP = false;
                        this.showOTP1 = false;
                    })
                    .catch( err => {
                        Swal.fire(
                            'Sorry, login failed',
                            'You may try again up to 5 times',
                            'error'
                        )
                        console.log(err);
                        this.$Progress.fail();
                    })
            },
        },
        props: {
            apiurl: '',                     // This is set during the component call in filey.html
            // thisurl: '',
        },
        created() {
            // Register the logOut event
            Fire.$on('logOut',() => {               
                this.logOut();
            });          
            



            // // Get the url that the front end is being accessed from - OLD - doesn't work in iframe
            // this.$root.fronturl = window.location.protocol + 
            //     "//" + window.location.host + window.location.pathname;


            var hostnameParts = window.location.host.split('.');
            
            // Get the front end subdomain, such as "fronturl".myfiley.com
            this.$root.subdomain = hostnameParts[0];
            this.form.subdomain = hostnameParts[0];
            // console.log('subdomain: ' + this.form.subdomain);

            // Get the root domains so we can generate the url for the back end API
            var domain = hostnameParts[1];
            for (let i=2; i<hostnameParts.length; i++) {
                domain += '.' + hostnameParts[i]; 
            }
            // console.log('domain: ' + domain);

            // If apirul was sent in the component call
            if(typeof apiurl !== 'undefined'){
                console.log('passed in apiurl: ' + apiurl);
                this.$root.apiurl = 'https://' + apiurl;
                var hostnameParts = apiurl.split('.');
                this.$root.subdomain = hostnameParts[0];
                this.form.subdomain = hostnameParts[0];
            } else {
                console.log('No passed in apiurl');
                this.$root.apiurl = 'https://' + this.form.subdomain + '.' + domain;
            }
            
            console.log('subdomain: ' + this.form.subdomain);
            console.log('apiurl: ' + this.$root.apiurl);


            // --- If the Vue was embedded in the client website with an iframe --- //
            // Environment Detection - See if filey was pulled in via iframe
            const parentUrlParams = new URLSearchParams(window.location.search);
            console.log('window.location.search: ' + window.location.search);
            
            if (parentUrlParams.get('fronturl')) {
                var parentUrlParamParts = parentUrlParams.get('fronturl').split('?');
                if (parentUrlParamParts.length > 0)
                    this.$root.fronturl = parentUrlParamParts[0];

                // Get the part of the url after the ? to see if 
                // "fileyuser" or "fileyresetpasswordcode" were sent 
                var args = window.location.search.split('?')[2];
                console.log('args: ' + args);
                const otherUrlParams = new URLSearchParams(args);
                this.form.email = otherUrlParams.get('fileyuser');
                this.form.resetpasswordcode = otherUrlParams.get('fileyresetpasswordcode');

            } else {
                // Not in an iframe.  
                // Get the url that the front end is being accessed from
                this.$root.fronturl = window.location.protocol + 
                    "//" + window.location.host + window.location.pathname;
                // See if "fileyuser" or "fileyresetpasswordcode" were sent 
                this.form.email = parentUrlParams.get('fileyuser');
                this.form.resetpasswordcode = parentUrlParams.get('fileyresetpasswordcode');
            }

            console.log('fronturl: ' + this.$root.fronturl);
            console.log('fileyuser: ' + this.form.email);
            console.log('fileyresetpasswordcode: ' + this.form.resetpasswordcode);

            // If resetpasswordcode was sent, show the form
            if (this.form.resetpasswordcode) {
                this.showForgotPasswordForm();
            }  

            // See if there is already an authentication token in the browser's cookie
            var fileytoken = '';
            var cookies = document.cookie;
            // console.log('cookies:' + cookies);
            if (cookies) {
                fileytoken = cookies
                    .split('; ')
                    .find(row => row.startsWith('fileytoken='))
                if (fileytoken) {
                    this.$root.access_token = fileytoken.split('=')[1];
                    this.logIn();
                }
            }
            console.log('fileytoken:' + fileytoken);


        }
    }
</script>