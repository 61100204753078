<template>
    <div>
        <div class="card-body">
            <div class="table-responsive">
            <table v-if="files.length && !loading" class="table table-hover mb-0 file-list-table">
                <thead>
                    <tr class="file-list-header">
                        <th @click="resort('filename')" class="pointer">
                            File Name <i v-if="current_sort=='filename' && sort_direction=='asc'" class="fas fa-angle-up" />
                            <i v-if="current_sort=='filename' && sort_direction=='desc'" class="fas fa-angle-down" />
                        </th>
                        <th @click="resort('description')" class="pointer">
                            Description <i v-if="current_sort=='description' && sort_direction=='asc'" class="fas fa-angle-up" />
                            <i v-if="current_sort=='description' && sort_direction=='desc'" class="fas fa-angle-down" />
                        </th>
                        <th @click="resort('created_at')" class="pointer text-nowrap">
                            Uploaded <i v-if="current_sort=='created_at' && sort_direction=='asc'" class="fas fa-angle-up" />
                            <i v-if="current_sort=='created_at' && sort_direction=='desc'" class="fas fa-angle-down" />
                        </th>
                        <th @click="resort('size')" class="pointer text-nowrap">
                            File Size <i v-if="current_sort=='size' && sort_direction=='asc'" class="fas fa-angle-up" />
                            <i v-if="current_sort=='size' && sort_direction=='desc'" class="fas fa-angle-down" />
                        </th>
                        <th align="center" class="text-center">Uploaded By</th>
                        <th align="center" class="text-center" style="min-width:100px">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="file in files" :key="file.id" >
                        <td v-if="!file.downloaded && file.uploaded_by != $root.user.id"><i class="fas fa-star new" title="New File" style="padding-right:5px;"></i><span :title="file.filename"><a href="#" @click.prevent="downloadFile(file)">{{ getShortFilename(file.filename) }}</a></span></td>
                        <td v-else ><span :title="file.filename"><a href="#" @click.prevent="downloadFile(file)">{{ getShortFilename(file.filename) }}</a></span></td>
                        <td>
                            <span :title="file.description">
                                {{ getShortDescription(file.description) }}
                            </span>
                        </td>
                        <td>{{file.created_at | prettyDate}}</td>
                        <td class="text-nowrap">{{file.size | prettyFileSize}}</td>
                        <td v-if="file.uploaded_by == $root.user.id" align="center" class="text-center text-nowrap">
                            You</td>
                        <td v-else-if="$root.user.user_type == 'admin'" align="center" class="text-center">
                            Client</td>
                        <td v-else-if="$root.user.user_type != 'admin'" align="center" class="text-center">
                            {{$root.adminName}}</td>
                        <td align="center">
                            <a href="#" @click.prevent="downloadFile(file)">
                                <i class="fas fa-cloud-download-alt" title="Download File"></i></a>
                            <a href="#" @click.prevent="editModal(file)">
                                <i class="fas fa-edit" title="Edit File" ></i></a>
                            <a href="#" @click.prevent="deleteFile(file.id, file.filename)">
                                <i class="fas fa-trash-alt" title="Delete File"></i></a>
                        </td>
                    </tr>
                    </tbody>
            </table>
            <table v-if="!files.length && !loading" class="table mb-0">
                <tr class="no-files">
                    <td align="center" class="text-center">No files to display.</td>
                </tr>
            </table>
            <table v-if="loading" class="table mb-0">
                <tr class="loading">
                    <!-- <td align="center" class="text-center"><span class="loading1">&#9679;</span> &nbsp; <span class="loading2">&#9679;</span> &nbsp; <span class="loading3">&#9679;</span></td> -->
                    <td align="center" class="text-center">
                        <div>
                            <b-spinner small label="Small Spinner"></b-spinner>
                        </div>
                    </td>
                </tr>
            </table>
            </div>
        </div><!-- card-body -->


        <!-- Download Progress Modal -->
        <b-modal 
            id="downloadmodal"
            size="lg" 
            centered 
            modal-class="filey"
            tabindex="-1" 
            role="dialog" 
            title="File Downloading..." 
            hide-footer
        >
            <div class="modal-body">
                <div id="downloadPercentDiv">
                    <progress max="100" :value.prop="percentComplete"></progress>
                    <div class="text-center">{{ percentComplete }} %</div>
                </div>
                <div class="text-center mt-3" id="downloadLinkDiv"></div>
            </div>
        </b-modal><!-- Download Progress Modal-->



        <!-- File Modal -->
        <b-modal 
            id="fileModal" 
            size="lg" 
            scrollable 
            centered
            static
            title="Update File"
            ok-title="Update File" 
            @ok="updateFileOkButton"
        >
        <form @submit.prevent="updateFile()">
            <div class="modal-body">
                <div class="form-group row">
                    <label for="inputFilename" class="col-sm-5 col-form-label text-right">File Name</label>
                    <div class="col-sm-7">
                        <input v-model="form.filename" type="text" class="form-control" 
                            name="filename" :class="{ 'is-invalid': form.errors.has('filename') }">
                        <has-error :form="form" field="filename"></has-error>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputDescription" class="col-sm-5 col-form-label text-right">Description</label>
                    <div class="col-sm-7">
                        <textarea v-model="form.description" type="text" class="form-control" rows="2"
                            name="description" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                        <has-error :form="form" field="description"></has-error>
                    </div>
                </div>
                <div v-if="form.created_at" class="form-group row">
                    <label for="inputUploadedAt" class="col-sm-5 col-form-label text-right">File Uploaded at</label>
                    <div class="col-sm-7 col-form-label ">{{form.created_at | prettyDateTime}}</div>
                </div>
                <div v-if="$root.user.user_type == 'admin'" class="form-group row">
                    <label for="inputUploadedAt" class="col-sm-5 col-form-label text-right">File Downloaded</label>
                    <div v-if="form.downloaded == 0" class="col-sm-7 col-form-label">No</div>
                    <div v-if="form.downloaded != 0" class="col-sm-7 col-form-label">Yes</div>
                </div>
            </div>
        </form>
        </b-modal><!-- File Modal -->
    </div><!-- Card -->
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    id: '',
                    file: null,
                    filename: '',
                    description: '',
                    created_at: '',
                    downloaded: '',
                }),
                files: [], 
                current_sort: 'created_at',  // Start by storting on created_at 
                sort_direction: 'desc',       // This will make it start by sorting descending
                loading: true,
                percentComplete: 0,
            }
        },
        props: {
            apiurl: '',          // This is set during the component call in filey.html
            user_id: '',
        },
        methods: {
            loadFiles(){
                this.loading=true;
                this.$Progress.start();
                console.log('Loading File list from: '+this.$root.apiurl+"/api/file?user_id="+this.user_id+"&sort="+this.current_sort+"&direction="+this.sort_direction)
                axios.get(this.$root.apiurl+"/api/file?user_id="+this.user_id+"&sort="+this.current_sort+"&direction="+this.sort_direction)
                    .then( ({data}) => {
                        // ({ data }) => (this.files = data.data)
                        this.files = data.data;
                        this.$Progress.finish();
                        this.loading=false;
                    });
            },
            resort(sort = this.current_sort){    // If sort isn't sent, use the current sort
                this.$Progress.start();
                // If it's already sorted by the column clicked and is asccending, 
                if (sort == this.current_sort && this.sort_direction == 'asc'){     
                    this.sort_direction = 'desc';   // make it descending.
                } else {
                    this.sort_direction = 'asc';    // otherwise, make it ascending
                } 

                this.current_sort = sort;           // Update current sort to the new sort
                axios.get(this.$root.apiurl+"/api/file?user_id="+this.user_id+"&sort="+sort+"&direction="+this.sort_direction)
                    .then(
                        ({ data }) => (this.files = data.data)
                    );
                this.$Progress.finish();
            },
            selectFile(event) {
                // `files` is always an array because the file input may be in multiple mode
                this.form.file = event.target.files[0];
                this.form.filename = this.form.file.name;
                this.form.size = this.form.file.size;
            },
            updateFileOkButton(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.updateFile()
            },
            updateFile(){
                this.$Progress.start(); 
                // console.log('in updateFile')
                this.form.put(this.$root.apiurl+'/api/file/'+this.form.id)         // Save the data
                    .then( ()=>{                                // If successful:
                        Fire.$emit('ReloadFiles')               // Reload files
                        this.$bvModal.hide('fileModal');
                        // $('#fileModal').modal('hide')           // Close the modal window
                        Toast.fire({                            // Pop up success message
                            icon: 'success',
                            title: 'File Updated Successfully'
                        })
                        this.$Progress.finish();                // Finish the progress bar
                    })
                    .catch( ()=>{
                        this.$Progress.fail();          // Finish the progress bar
                    }); 
            },
            deleteFile(id, filename) {
                Swal.fire({
                    html: 'Are you sure you want to delete the file <br />' + filename,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        if (result.value) {                         // If the user confirmed, 
                            this.$Progress.start();
                            console.log('deleting file...');
                            this.form.delete(this.$root.apiurl+'/api/file/'+id)       // Send request to server
                                .then(()=>{                         
                                    Toast.fire({                            // Pop up success message
                                        icon: 'success',
                                        title: 'File Deleted'
                                    })
                                    Fire.$emit('ReloadFiles')       // Reload files
                                        this.$Progress.finish();    // Finish the progress bar
                                    })
                                .catch(()=> {
                                    Toast.fire({                            // Pop up success message
                                        icon: 'success',
                                        title: 'File Deleted'
                                    })
                                    this.$Progress.fail();          // Finish the progress bar
                                });
                            }
                        })
            },
            downloadFile(file){
                this.$Progress.start();                         // Start the progress bar
                // Swal.fire({
                //     title: "Downloading File",
                //     text: "Large files may take a while...",
                //     showConfirmButton: false,
                //     allowOutsideClick: false,
                //     didOpen: () => {
                //         Swal.showLoading();                 // Show the spinner
                //     }
                // });

                this.percentComplete = 0;
                this.$bvModal.show('downloadmodal');
                this.modalHack();      // Add the 'filey' class to the boostrap-vue modal

                console.log(file.id);
                console.log(file.filename);

                // axios.get(this.$root.apiurl+'/api/download/'+file.id, {responseType: 'arraybuffer'})

                // Create and get the temporary location of the file on Amazon S3
                axios.get(this.$root.apiurl+'/api/download/'+file.id)
                .then(res => {
                    console.log("file to download: "); 
                    console.log(res.data);

                    // Detect if the client is on iOS.  If so, we need to feed the data differently.
                    let mobileOperatingSystem = this.getMobileOperatingSystem();
                    let onMobile = (mobileOperatingSystem == 'iOS' || mobileOperatingSystem == 'Safari' ) // Hack for Chrome iOS
                        
                    console.log("Mobile detected.");
                    console.log(mobileOperatingSystem);

                    if (onMobile) {  
                    // if (1) {   // for testing

                        // Hide download percent part of pop-up
                        var downloadPercentDiv = document.getElementById("downloadPercentDiv");
                        downloadPercentDiv.style.display = 'none';

                        // Show direct download link
                        var fileURL = res.data;
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('class','btn btn-primary');
                        fileLink.setAttribute('download', file.filename);
                        var downloadLinkDiv = document.getElementById("downloadLinkDiv");
                        var fileLinkText = document.createTextNode("Click Here to Save File");
                        fileLink.onclick = this.hideDownloadModal;
                        fileLink.appendChild(fileLinkText);
                        downloadLinkDiv.appendChild(fileLink);
                    } else {
                        // Then get the file from Amazon S3
                        axios.get(res.data, 
                            { 
                                responseType: 'blob',
                                // Remove the Authorization header from the S3 link
                                transformRequest: (data, headers) => {
                                    delete headers.common['Authorization'];
                                    return data;
                                },
                                onDownloadProgress: function( progressEvent ) {  // Show the realtime upload progress
                                    this.$Progress.set(parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )));
                                    this.percentComplete = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                                }.bind(this)
                            }
                        ).then (res2 => {                             
                            // this.percentComplete = 0;
                            if (!onMobile) { // Close the progress pop-up if not on mobile device
                                this.$bvModal.hide('downloadmodal');
                            }
                            let blob = new Blob([res2.data], {type:'application/*'})
                            let link = document.createElement('a')
                            link.href = window.URL.createObjectURL(blob)
                            link.download = file.filename
                            link.click()
                            URL.revokeObjectURL(link.href);

                            // // Another method
                            // var fileURL = window.URL.createObjectURL(new Blob([res2.data]));
                            // var fileLink = document.createElement('a');
                            // fileLink.href = fileURL;
                            // fileLink.setAttribute('download', file.filename);
                            // document.body.appendChild(fileLink);
                            // fileLink.click();

                        }).catch ( (err) => {
                            this.$bvModal.hide('downloadmodal');
                            // this.percentComplete = 0;
                            console.log(err);
                            this.$Progress.fail();                  // Finish the progress bar
                            Swal.close();                           // Close the Sweet Alert
                            Toast.fire({                            // Pop up error message
                                icon: 'error',
                                title: 'Something went wrong'
                            });
                        })
                    }
                    this.$nextTick(() => {  
                        Fire.$emit('ReloadFiles');              // to remove any "new" stars
                    });
                    this.$Progress.finish();                    // Finish the progress bar
                }).catch(console.error)
            },
            hideDownloadModal() {
                this.$bvModal.hide('downloadmodal');
            },
            editModal(file) {
                console.log('editModal');
                this.form.reset();
                if (typeof this.$refs.fileInput != "undefined") {
                    this.$refs.fileInput.value = '';        // Clear the file selector in the modal
                }
                this.$bvModal.show('fileModal');
                this.form.fill(file);
                console.log(file);
            },
            getShortFilename(filename) {
                let max = 30;

                if (filename == null) {
                    filename = ' ';
                }
                if (filename.length > max ) {
                    let fileExt = filename.split('.').pop();
                    if (fileExt.length < 5) {
                        return filename.substring(0, max - fileExt.length - 5) + '... .' + fileExt;
                    } else {
                        return filename;
                    }
                } else {
                    return filename;
                }
            },
            getShortDescription(description) {
                let max = 30;
                if (description == null) {
                    description = ' ';
                }
                if (description.length > max ) {
                    return description.substring(0, max) + '...';
                } else {
                    return description;
                }
            },
            getMobileOperatingSystem() {
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                console.log(userAgent);
                // Windows Phone must come first because its UA also contains "Android"
                if (/windows phone/i.test(userAgent)) {
                    return "Windows Phone";
                }

                if (/android/i.test(userAgent)) {
                    return "Android";
                }

                // iOS detection from: http://stackoverflow.com/a/9039885/177710
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    return "iOS";
                }

                if (userAgent.includes("Safari")) {
                    return "Safari";
                }

                return userAgent;
            }
        },
        created() {
            console.log('FileList: API: ' + this.$root.apiurl);
            this.loadFiles();                           // Load files when the component loads
            // Fire.$on('ReloadFiles',() => {              // Register the ReloadFiles event
            //     this.loadFiles();
            // });
            Fire.$on('ReloadFiles', this.loadFiles);
        },
        beforeDestroy() {
            Fire.$off('ReloadFiles', this.loadFiles);
        }
    }
</script>
