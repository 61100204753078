<template>
    <div style="display: inline-block;">
        <button type="button" class="btn btn-primary btn-sm mr-2 request-file-button-large" @click="requestFileModal()">
            Request File<i class="fas fa-envelope" style="padding-left:5px;"></i>
        </button>
        <span style="padding-right:1px; display:none;" class="request-file-button-small"><a href="#" @click.prevent="requestFileModal()">
            <i class="fas fa-envelope"  title="Request File" style="padding-right:3px;"></i></a>
        </span>


        <!-- Request File Modal -->
        <b-modal 
            :id="'requestFileModal'+user_id"
            size="lg" 
            scrollable 
            centered 
            :title="(company_name === undefined)?'Request File':'Request File from ' + company_name" 
            ok-title="Send Request" 
            @ok="sendRequestOkButton"
        >
        <form @submit.prevent="sendRequest()">
            <div class="modal-body">
                <div class="form-group row">
                    <label for="inputSubject" class="col-sm-4 col-form-label text-right">Subject</label>
                    <div class="col-sm-8">
                        <input v-model="form.subject" type="text" class="form-control" 
                            name="subject" :class="{ 'is-invalid': form.errors.has('subject') }">
                        <has-error :form="form" field="subject"></has-error>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputNote" class="col-sm-4 col-form-label text-right">Message</label>
                    <div class="col-sm-8">
                        <textarea v-model="form.note" type="text" class="form-control" rows="4" placeholder="This is the body of the email.  Explain what file you would like them to upload."
                            name="note" :class="{ 'is-invalid': form.errors.has('note') }"></textarea>
                        <has-error :form="form" field="note"></has-error>
                    </div>
                </div>
            </div>
        </form>
        </b-modal><!-- Request File Modal -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    id: '',
                    subject: '',
                    note: '',
                    created_at: '',
                    user_id: '',
                    fronturl: this.$root.fronturl, 
                }),
                apiurl: this.$root.apiurl, 
                files: [], 
            }
        },
        props: {
            user_id: '',            // This needs to be passed in the component call from the parent
            company_name: '',
        },
        methods: {
            requestFileModal() {
                this.form.reset();
                this.form.subject = this.$root.adminCompany + ' has requested that you upload a file',
                this.$bvModal.show('requestFileModal'+this.user_id);
                
                // Hack to add the 'filey' class to the boostrap-vue modal outer wrapper
                // iPhones make "static" b-modals pop up inside the parent container visually, 
                // so we have to add the class to all modals instead
                this.$nextTick(() => {              // Wait for the modal to load in the DOM
                    var modalList;
                    modalList = document.querySelectorAll('[id$=_BV_modal_outer_]');
                    for (var i = 0; i < modalList.length; ++i) {
                        modalList[i].classList.add('filey');
                    }
                });
            },
            sendRequestOkButton(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.sendRequest()
            },
            sendRequest(){
                this.$Progress.start();                     // Start the progress bar
                // Swal.fire({
                //     title: "Sending Request",
                //     showConfirmButton: false,
                //     allowOutsideClick: false,
                //     didOpen: () => {
                //         Swal.showLoading();                 // Show the spinner
                //     }
                // });
                this.form.user_id = this.user_id;
                this.form.submit('post',this.$root.apiurl+'/api/sendfilerequest')
                    .then( ()=>{                                // If successful:
                        // $('#requestFileModal'+this.user_id).modal('hide')    // Close the modal window
                        this.$bvModal.hide('requestFileModal'+this.user_id);
                        Toast.fire({                            // Pop up success message
                            icon: 'success',
                            title: 'Request Sent'
                        })
                        this.$Progress.finish();                // Finish the progress bar
                    })
                    .catch( ()=>{
                        this.$Progress.fail();             // Finish the progress bar
                    }); 
            },
        },
        created() {
            // console.log('AddFileButton.vue: using API: ' + this.$root.apiurl);
            // console.log('AddFileButton: fronturl: ' + this.form.fronturl);
        }
    }
</script>
