<template>
    <!-- <div style="display: inline-block;"> -->
        <!-- <button type="button" class="btn btn-primary btn-sm mr-2" @click="newFileListModal()">
            New Files<i class="fas fa-envelope" style="padding-left:5px;"></i>
        </button> -->


        <!-- New File List Modal -->
        <!-- <div class="modal fade" id="newFileListModal" tabindex="-1" role="dialog" aria-labelledby="newFileListModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="newFileListModalLabel">New File List</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
 -->


    <div>
        <div class="card-body">
            <div class="table-responsive">
            <table v-if="files.length && !loading" class="table table-hover mb-0 file-list-table">
                <thead>
                    <tr class="file-list-header">
                        <th @click="resort('company_name')" class="pointer">Uploaded By</th>
                        <th @click="resort('filename')" class="pointer">
                            File Name <i v-if="current_sort=='filename' && sort_direction=='asc'" class="fas fa-angle-up" />
                            <i v-if="current_sort=='filename' && sort_direction=='desc'" class="fas fa-angle-down" />
                        </th>
                        <th @click="resort('description')" class="pointer">
                            Description <i v-if="current_sort=='description' && sort_direction=='asc'" class="fas fa-angle-up" />
                            <i v-if="current_sort=='description' && sort_direction=='desc'" class="fas fa-angle-down" />
                        </th>
                        <th @click="resort('created_at')" class="pointer text-nowrap">
                            Uploaded <i v-if="current_sort=='created_at' && sort_direction=='asc'" class="fas fa-angle-up" />
                            <i v-if="current_sort=='created_at' && sort_direction=='desc'" class="fas fa-angle-down" />
                        </th>
                        <th @click="resort('size')" class="pointer text-nowrap">
                            File Size <i v-if="current_sort=='size' && sort_direction=='asc'" class="fas fa-angle-up" />
                            <i v-if="current_sort=='size' && sort_direction=='desc'" class="fas fa-angle-down" />
                        </th>
                        <th align="center" class="text-center" style="min-width:100px">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="file in files" :key="file.id" >
                        <td>{{ file.company_name }}</td>
                        <!-- <td v-if="!file.downloaded && file.uploaded_by != $root.user.id"><i class="fas fa-star new" title="New File" style="padding-right:5px;"></i>{{file.filename}}</td> -->
                        <td><a href="#" @click.prevent="downloadFile(file)">{{file.filename}}</a></td>
                        <td>
                            <span :title="file.description">
                                {{ getShortDescription(file.description) }}
                            </span>
                        </td>
                        <td>{{ file.created_at | prettyDate }}</td>
                        <td class="text-nowrap">{{file.size | prettyFileSize}}</td>
                        <td align="center">
                            <a href="#" @click.prevent="downloadFile(file)">
                                <i class="fas fa-cloud-download-alt" title="Download File"></i></a>&nbsp;
                            <a href="#" @click.prevent="editModal(file)">
                                <i class="fas fa-edit" title="Edit File" ></i></a>&nbsp;
                            <a href="#" @click.prevent="deleteFile(file.id)">
                                <i class="fas fa-trash-alt" title="Delete File"></i></a>
                        </td>
                    </tr>
                    </tbody>
            </table>
            <table v-if="!files.length && !loading" class="table mb-0">
                <tr class="no-files">
                    <td align="center" class="text-center">No files to display.</td>
                </tr>
            </table>
            <table v-if="loading" class="table mb-0">
                <tr class="loading">
                    <!-- <td align="center" class="text-center"><span class="loading1">&#9679;</span> &nbsp; <span class="loading2">&#9679;</span> &nbsp; <span class="loading3">&#9679;</span></td> -->
                    <td align="center" class="text-center">
                        <div>
                            <b-spinner small label="Small Spinner"></b-spinner>
                        </div>
                    </td>
                </tr>
            </table>
            </div>
        </div><!-- card-body -->

<!-- </div> -->
                    <!-- <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Send Request</button>
                    </div>
                </div>
            </div>
            </div> -->
            <!-- Request File Modal -->


        
        <!-- File Modal -->
        <b-modal 
            id="fileModal" 
            size="lg" 
            scrollable 
            centered 
            static
            title="Update File"
            ok-title="Update File" 
            @ok="updateFileOkButton"
        >
        <form @submit.prevent="updateFile()">
            <div class="modal-body">
                <div class="form-group row">
                    <label for="inputFilename" class="col-sm-5 col-form-label text-right">File Name</label>
                    <div class="col-sm-7">
                        <input v-model="form.filename" type="text" class="form-control" 
                            name="filename" :class="{ 'is-invalid': form.errors.has('filename') }">
                        <has-error :form="form" field="filename"></has-error>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputDescription" class="col-sm-5 col-form-label text-right">Description</label>
                    <div class="col-sm-7">
                        <textarea v-model="form.description" type="text" class="form-control" rows="2"
                            name="description" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                        <has-error :form="form" field="description"></has-error>
                    </div>
                </div>
                <div v-if="form.created_at" class="form-group row">
                    <label for="inputUploadedAt" class="col-sm-5 col-form-label text-right">File Uploaded at</label>
                    <div class="col-sm-7 col-form-label ">{{form.created_at | prettyDateTime}}</div>
                </div>
            </div>
        </form>
    </b-modal><!-- File Modal -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    id: '',
                    file: null,
                    filename: '',
                    description: '',
                    created_at: '',
                    company_name: '',
                }),
                files: [], 
                current_sort: 'created_at',  // Start by storting on created_at 
                sort_direction: 'desc',       // This will make it start by sorting descending
                loading: true,
            apiurl: this.$root.apiurl,
            }
        },
        props: {
            user_id: '',
        },
        methods: {
            loadFiles(){
                this.loading=true;
                this.$Progress.start();
                console.log('Loading File list from: '+this.$root.apiurl+"/api/newfilelist?sort="+this.current_sort+"&direction="+this.sort_direction)
                axios.get(this.$root.apiurl+"/api/newfilelist?sort="+this.current_sort+"&direction="+this.sort_direction)
                    .then( ({data}) => {
                        // ({ data }) => (this.files = data.data)
                        this.files = data.data;
                        this.$Progress.finish();
                        this.loading=false;
                    });
            },
            resort(sort = this.current_sort){    // If sort isn't sent, use the current sort
                this.$Progress.start();
                // If it's already sorted by the column clicked and is asccending, 
                if (sort == this.current_sort && this.sort_direction == 'asc'){     
                    this.sort_direction = 'desc';   // make it descending.
                } else {
                    this.sort_direction = 'asc';    // otherwise, make it ascending
                } 

                this.current_sort = sort;           // Update current sort to the new sort
                axios.get(this.$root.apiurl+"/api/newfilelist?sort="+sort+"&direction="+this.sort_direction)
                    .then(
                        ({ data }) => (this.files = data.data)
                    );
                this.$Progress.finish();
            },
            newFileListModal() {
                this.loadFiles();
                $('#newFileListModal').modal('show')

                // Hack to move the modal-backdrop within a "filey" div for filey-for-bootstrap
                var modalHolder = $('<div class="filey"></div>');
                $('body').append(modalHolder);
                $('.modal-backdrop').first().appendTo(modalHolder); 
            },
            selectFile(event) {
                // `files` is always an array because the file input may be in multiple mode
                this.form.file = event.target.files[0];
                this.form.filename = this.form.file.name;
                this.form.size = this.form.file.size;
            },
            uploadFile(){
                this.$Progress.start();                         // Start the progress bar
                this.form.submit('post',this.$root.apiurl+'/api/file', {
                    // Transform form data to FormData for the vform
                    transformRequest: [function (data, headers) {
                        return objectToFormData(data)
                    }]
                })
                .then( ()=>{                                // If successful:
                    Fire.$emit('ReloadFiles')               // Reload files
                    $('#fileModal').modal('hide')           // Close the modal window
                    Toast.fire({                            // Pop up success message
                        icon: 'success',
                        title: 'File Added Successfully'
                    })

                    this.$Progress.finish();                // Finish the progress bar
                })
                .catch( ()=>{
                    this.$Progress.fail();          // Finish the progress bar
                }); 
            },
            updateFileOkButton(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.updateFile()
            },
            updateFile(){
                this.$Progress.start(); 
                console.log('in updateFile')
                this.form.put(this.$root.apiurl+'/api/file/'+this.form.id)         // Save the data
                    .then( ()=>{                                // If successful:
                        Fire.$emit('ReloadFiles')               // Reload files
                        $('#fileModal').modal('hide')           // Close the modal window
                        Toast.fire({                            // Pop up success message
                            icon: 'success',
                            title: 'File Updated Successfully'
                        })
                        this.$Progress.finish();                // Finish the progress bar
                    })
                    .catch( ()=>{
                        this.$Progress.fail();          // Finish the progress bar
                    }); 
            },
            deleteFile(id) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to undo this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        if (result.value) {                         // If the user confirmed, 
                            this.$Progress.start();
                            console.log('deleting file...');
                            this.form.delete(this.$root.apiurl+'/api/file/'+id)       // Send request to server
                                .then(()=>{                         
                                    Toast.fire({                            // Pop up success message
                                        icon: 'success',
                                        title: 'File Deleted'
                                    })
                                    Fire.$emit('ReloadFiles')       // Reload files
                                        this.$Progress.finish();    // Finish the progress bar
                                    })
                                .catch(()=> {
                                    Toast.fire({                            // Pop up success message
                                        icon: 'success',
                                        title: 'File Deleted'
                                    })
                                    this.$Progress.fail();          // Finish the progress bar
                                });
                            }
                        })
            },
            downloadFile(file){
                console.log(file.id);
                console.log(file.filename);
                // axios.get(this.$root.apiurl+'/api/download/'+id);  // for testing
                axios.get(this.$root.apiurl+'/api/download/'+file.id, {responseType: 'arraybuffer'})
                .then(res=>{
                    let blob = new Blob([res.data], {type:'application/*'})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = file.filename
                    link.click()
                    URL.revokeObjectURL(link.href);
                    Fire.$emit('ReloadFiles');
                }).catch(console.error)
            },
            editModal(file) {
                console.log('fileModal');
                this.form.reset();
                if (typeof this.$refs.fileInput != "undefined") {
                    this.$refs.fileInput.value = '';        // Clear the file selector in the modal
                }
                this.$bvModal.show('fileModal');
                this.form.fill(file);
                console.log(file);
            },
            getShortDescription(description) {
                let max = 30;
                if (description == null) {
                    description = ' ';
                }
                if (description.length > max ) {
                    return description.substring(0, max) + '...';
                } else {
                    return description;
                }
            }
        },
        created() {
            console.log('FileList: API: ' + this.$root.apiurl);
            this.loadFiles();                           // Load files when the component loads
            // Fire.$on('ReloadFiles',() => {              // Register the ReloadFiles event
            //     this.loadFiles();
            // });
            Fire.$on('ReloadFiles', this.loadFiles);
        },
        beforeDestroy() {
            Fire.$off('ReloadFiles', this.loadFiles);
        }
    }
</script>