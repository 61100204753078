<template>
    <div v-if="$root.user.user_type == 'client'" class="container mt-3">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card file-list">
                    <div class="card-header">
                        <div style="float:left"><h2 style="margin-bottom:0;">{{$root.user.first_name}}</h2></div>
                        <div style="float:right"> 
                            <span style="padding-right:10px;">
                                <add-file-button 
                                    :user_id="$root.user.id" 
                                    :apiurl="apiurl"
                                    :company_name="$root.adminCompany"
                                />
                            </span>
                            <span style="padding-right:10px;"><a href="#" @click.prevent="settingsModal()">
                                <i class="fas fa-cog" style="padding-right:3px;"></i><span class="settings-button-label">Settings</span></a>
                            </span>
                            <span style="padding-right:10px;"><a href="#" @click.prevent="logOut">
                                <i class="fas fa-sign-out-alt" style="padding-right:3px;"></i><span class="log-out-button-label">Log Out</span></a>
                            </span>
                        </div>
                    </div>
                    <file-list 
                        :key="$root.user.id"
                        :user_id="$root.user.id"
                        :apiurl="apiurl"
                    />
                </div>
            </div>
        </div>

<!-- Settings Modal -->
        <b-modal 
            id="settingsModal" 
            size="lg" 
            scrollable 
            centered 
            static
            title="Update Settings"
            ok-title="Update Settings" 
            @ok="updateSettingsOkButton"
        >
        <form @submit.prevent="updateSettings()">
            <!-- <div class="modal fade" id="settingsModal" tabindex="-1" role="dialog" aria-labelledby="settingsModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="settingsModalLabel">Update Settings</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> -->
                    <div class="modal-body">
                        <div class="form-group row">
                            <label for="inputCompanyName" class="col-sm-5 col-form-label text-right">Company Name</label>
                            <div class="col-sm-7">
                                <input v-model="form.company_name" type="text" class="form-control" 
                                    name="customer_name" :class="{ 'is-invalid': form.errors.has('company_name') }">
                                <has-error :form="form" field="company_name"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputFirstName" class="col-sm-5 col-form-label text-right">First Name</label>
                            <div class="col-sm-7">
                                <input v-model="form.first_name" type="text" class="form-control" 
                                    name="first_name" :class="{ 'is-invalid': form.errors.has('first_name') }">
                                <has-error :form="form" field="first_name"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputLastName" class="col-sm-5 col-form-label text-right">Last Name</label>
                            <div class="col-sm-7">
                                <input v-model="form.last_name" type="text" class="form-control" 
                                    name="last_name" :class="{ 'is-invalid': form.errors.has('last_name') }">
                                <has-error :form="form" field="last_name"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputEmail" class="col-sm-5 col-form-label text-right">Email</label>
                            <div class="col-sm-7">
                                <input v-model="form.email" type="email" class="form-control" 
                                    name="email" :class="{ 'is-invalid': form.errors.has('email') }">
                                <has-error :form="form" field="email"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputOfficeNumber" class="col-sm-5 col-form-label text-right">Office Number</label>
                            <div class="col-sm-7">
                                <input v-model="form.office_number" type="tel" class="form-control"  
                                    name="office_number" :class="{ 'is-invalid': form.errors.has('office_number') }">
                                <has-error :form="form" field="office_number"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputMobileNumber" class="col-sm-5 col-form-label text-right">Mobile Number</label>
                            <div class="col-sm-7">
                                <input v-model="form.mobile_number" type="tel" class="form-control" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" title="225-555-1234"
                                    name="mobile_number" :class="{ 'is-invalid': form.errors.has('mobile_number') }">
                                <has-error :form="form" field="mobile_number"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-5 col-form-label text-right">Password</label>
                            <div class="col-sm-7">
                                <input v-model="form.password" type="password" class="form-control" 
                                    name="password" :class="{ 'is-invalid': form.errors.has('password') }">
                                <has-error :form="form" field="password"></has-error>
                            </div>
                        </div>

                        <!-- <div class="form-group row">
                            <label for="inputPreferredAuthMethod" class="col-sm-5 col-form-label text-right">Preferred Authentication Method</label>
                            <div class="col-sm-7">
                                <select v-model="form.preferred_auth_method" type="select" class="form-control" name="form.preferred_auth_method">
                                    <option value="password">Password</option>
                                    <option value="otp">Send One Time Password Via Text</option>
                                </select>
                                <has-error :form="form" field="preferred_auth_method"></has-error>
                            </div>
                        </div> -->

                        <!-- <div v-if="form.created_at" class="form-group row">
                            <label for="inputCreatedAt" class="col-sm-5 col-form-label text-right">User Created at</label>
                            <div class="col-sm-7 col-form-label ">{{form.created_at | prettyDateTime}}</div>
                        </div> -->

                    </div>
                    <!-- <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Update Settings</button>
                    </div> -->
                <!-- </div>
            </div>
            </div> -->
        </form>
        </b-modal><!-- Settings Modal -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    id: '',
                    email: '',
                    password: '',
                    company_name: '',
                    first_name: '',
                    last_name: '',
                    office_number: '',
                    mobile_number: '',
                }),
                apiurl: this.$root.apiurl,       // Pull from the global set by the login component
            }
        },
        methods: {
            settingsModal() {
                this.form.reset();
                this.form.errors.clear();
                
                // do the axios request to get user info
                this.$Progress.start();                         // Start the progress bar
                console.log('settingsModal: Loading user info from api: ' + this.$root.apiurl);
                axios.get(this.$root.apiurl+"/api/user/"+this.$root.user.id)
                    .then( res => {
                        console.log(res);
                        this.form.fill(res.data);            
                        this.$bvModal.show('settingsModal');
                        this.$Progress.finish();                
                    }).catch( err => {
                        console.log('settingsModal: ' + err)
                        this.$Progress.fail();                
                    });
            },
            logOut() {
                Fire.$emit('logOut')                    // Call the logOut method in LoginComponent
            },
            updateSettingsOkButton(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.updateSettings()
            },
            updateSettings(){
                this.$Progress.start(); 
                this.form.put(this.$root.apiurl+'/api/user/'+this.form.id)         // Save the data
                    .then( ()=>{                                // If successful:
                        this.$root.user.first_name = this.form.first_name;
                        Toast.fire({                            // Pop up success message
                            icon: 'success',
                            title: 'Settings Updated Successfully'
                        })
                        this.$Progress.finish();                // Finish the progress bar
                        // $('#settingsModal').modal('hide')       // Close the modal window
                        this.$bvModal.hide('settingsModal');
                    })
                    .catch( err => {
                        Toast.fire({                            // Pop up success message
                            icon: 'error',
                            title: 'Something went wrong'
                        })
                        this.$Progress.fail();                  // Finish the progress bar
                    }); 
            },
        },
        created() {

        }
    }

</script>
