<template>
    <div style="display: inline-block;">
        <button type="button" class="btn btn-primary btn-sm mr-2 add-file-button-large" @click="addModal()">
            Upload File<i class="fas fa-file-upload" style="padding-left:5px;"></i>
        </button>
        <span style="padding-right:1px; display:none;" class="add-file-button-small"><a href="#" @click.prevent="addModal()">
            <i class="fas fa-file-upload"  title="Upload File" style="padding-right:3px;"></i></a>
        </span>



        <!-- Upload Progress Modal -->
        <b-modal 
            :id="'progress'+user_id"
            size="sm" 
            centered 
            modal-class="filey"
            tabindex="-1" 
            role="dialog" 
            title="File Uploading..." 
            hide-footer
        >
            <div class="modal-body">
                <progress max="100" :value.prop="percentComplete"></progress>
                <div class="text-center">{{ percentComplete }} %</div>
            </div>
        </b-modal><!-- Upload Progress Modal-->


        <!-- File Modal -->
        <b-modal 
            :id="'fileModal'+user_id" 
            size="lg" 
            scrollable centered 
            modal-class="filey"
            tabindex="-1" 
            role="dialog" 
            :title="(company_name === undefined)?'Upload New File':'Upload New File for ' + company_name" 
            ok-title="Upload File"
            @ok="uploadFileOkButton"
        >
            <!-- File Modal -->
            <form @submit.prevent="uploadFile()">
                <div class="modal-body">
                    <div class="form-group row">
                        <label for="inputFile" class="col-sm-5 col-form-label text-right">File</label>
                        <div class="col-sm-7">
                            <input name="file" id="file" type="file" @change="selectFile" ref="fileInput" class="form-control">
                            <has-error :form="form" field="file"></has-error>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputFilename" class="col-sm-5 col-form-label text-right">File Name</label>
                        <div class="col-sm-7">
                            <input v-model="form.filename" type="text" class="form-control" 
                                name="filename" :class="{ 'is-invalid': form.errors.has('filename') }">
                            <has-error :form="form" field="filename"></has-error>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputDescription" class="col-sm-5 col-form-label text-right">Description<br />(optional)</label>
                        <div class="col-sm-7">
                            <textarea v-model="form.description" type="text" class="form-control" rows="2"
                                name="description" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                            <has-error :form="form" field="description"></has-error>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="sendemail" class="col-sm-5 col-form-label text-right">Send Email Notification</label>
                        <div class="col-sm-7 text-left">
                            <input type="checkbox" id="sendemail" v-model="form.sendemail"
                                class="form-control text-left" style="width:auto;" name="sendemail">
                        </div>
                    </div>
                </div>
            </form>
        </b-modal><!-- File Modal-->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    id: '',
                    file: null,
                    filename: '',
                    filepath: '',
                    description: '',
                    created_at: '',
                    user_id: '',
                    sendemail: true,
                    fronturl: this.$root.fronturl, 
                }),
                apiurl: this.$root.apiurl, 
                files: [], 
                percentComplete: 0,
            }
        },
        props: {
            // apiurl: '',             // This is set during the component call in filey.html
            user_id: '',            // This needs to be passed in the component call from the parent
            company_name: '',
        },
        methods: {
            selectFile(event) {
                // `files` is always an array because the file input may be in multiple mode
                this.form.file = event.target.files[0];
                this.form.filename = this.form.file.name;
                this.form.size = this.form.file.size;
            },
            randomString(length) {
                var result = ''
                var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
                var charactersLength = characters.length
                for (var i = 0; i < length; i++) {
                    result += characters.charAt( Math.floor( Math.random() * charactersLength) )
                }
                return result
            },
            randomFilename(filename) {
                // MySQL can save up to 191, minus 9 for random string, and user id,
                // so let's just say 170
                let max = 170;  
                let truncatedFilename = '';
                if (filename == null) {
                    filename = ' ';
                }
                if (filename.length > max ) {
                    let fileExt = filename.split('.').pop();
                    if (fileExt.length < 5) {
                        truncatedFilename = filename.substring(0, max - fileExt.length ) + '.' + fileExt;
                    } else {
                        truncatedFilename = filename.substring(0, max - fileExt.length );
                    }
                } else {
                    truncatedFilename = filename;
                }
                return this.randomString(8) + '-' + truncatedFilename;
            },
            uploadFileOkButton(bvModalEvt) {
                // Prevent modal from closing
                // bvModalEvt.preventDefault()
                // Trigger submit handler
                this.uploadFile()
            },
            uploadFile(){
                this.$bvModal.hide('fileModal'+this.user_id); 
                // this.$Progress.start();                         // Start the progress bar
                // Swal.fire({
                //     title: "Uploading File",
                //     text: "Large files may take a while... " + this.percentComplete + " %",
                //     showConfirmButton: false,
                //     allowOutsideClick: false,
                //     didOpen: () => {
                //         Swal.showLoading();                 // Show the spinner
                //     }
                // });

                this.$bvModal.show('progress'+this.user_id);
                this.modalHack();      // Add the 'filey' class to the boostrap-vue modal

                this.form.user_id = this.user_id;
                this.form.filepath = this.user_id + '/' + this.randomFilename(this.form.filename); 
                // Get create the URL to upload to
                this.form.post(this.$root.apiurl+'/api/createuploadurl')
                    .then( res => {  
                        let uploadUrl = res.data;                              
                        console.log('Uploading to: ' + uploadUrl);
                        console.log(res.data);

                        // Upload the file to Amazon S3
                        axios.put(uploadUrl, this.form.file, {
                            transformRequest: [function (data, headers) {  // Transform form data to FormData for the vform
                                delete headers.common['Authorization'];    // Remove the Authorization header from the S3 post
                                return data
                            }],
                            onUploadProgress: function( progressEvent ) {  // Show the realtime upload progress
                                this.$Progress.set(parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )));
                                this.percentComplete = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                            }.bind(this)
                        })
                        .then( () => { 

                            // Save the file info in our database
                            this.form.post(this.$root.apiurl+'/api/file')
                            .then( (res2) => { 
                                console.log(res2.data.message);
                                this.$bvModal.hide('progress'+this.user_id);
                                Fire.$emit('ReloadFiles')               // Reload files
                                Toast.fire({                            // Pop up success message
                                    icon: 'success',
                                    title: 'File Added Successfully'
                                });
                                this.$Progress.finish();                // Finish the progress bar
                                this.percentComplete = 0;
                            })
                            .catch( () => {
                                this.$bvModal.hide('progress'+this.user_id);
                                Toast.fire({                            // Pop up error message
                                    icon: 'error',
                                    title: 'Something went wrong'
                                });
                                this.$Progress.fail();          // Finish the progress bar
                                this.percentComplete = 0;
                            })

                        })
                        .catch( err => {
                            console.log(err);
                        })
                    })
                    .catch( err => {
                        console.log(err);
                    }); 

            },
            addModal() {
                this.form.reset();
                if (typeof this.$refs.fileInput != "undefined") {
                    this.$refs.fileInput.value = '';        // Clear the file selector in the modal
                }
                this.$bvModal.show('fileModal'+this.user_id); 
                this.modalHack();      // Add the 'filey' class to the boostrap-vue modal
            },
        },
        created() {

        }
    }
</script>
